import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, NavLink } from 'react-router-dom';

import { CustomIcon } from '~components/index';
import type { SidebarItemData } from './SidebarTypes';

interface Props {
  data: SidebarItemData;
  location: Location;
}
const SidebarItem = ({ data, location }: Props) => {
  const { t } = useTranslation();

  const style = useMemo(() => {
    if (data.power_bi) {
      return (
        `/platform/${data.functional_name}${location.search}` ===
        `${location.pathname}?${data.power_bi}`
      );
    } else return `/platform/${data.functional_name}` === location.pathname;
  }, [location]);

  return (
    <NavLink
      className={`sidebar_link ${style ? 'sidebar_link_active' : ''}`}
      to={
        !data.power_bi
          ? `/platform/${data.functional_name}`
          : `/platform/${data.functional_name}?${data.power_bi}`
      }
    >
      <CustomIcon name={data.icon || 'circle'} />
      <p className="text-break ms-2">{t(data.label)}</p>
    </NavLink>
  );
};

export default SidebarItem;
