import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Icon401 from '~assets/401.svg';
import { Button } from '~components/index';
import {
  ErrorCode,
  ErrorDataType,
  ErrorType,
  useAppStorage
} from '~store/useStore';
import './errorBoundaryBack.scss';

const ErrorBoundaryBack = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const { errorRequest, cleanError } = useAppStorage();
  const [tokenInvalid, setTokenInvalid] = useState(false);

  const validateError = (error: ErrorType) => {
    //* Check for token expiration
    const errorCode = error as ErrorCode;
    if (errorCode?.code) {
      if (errorCode.code === 'token_not_valid') {
        setTokenInvalid(true);
      } else {
        setTokenInvalid(false);
      }
    }
    //* Show toast if error is not an HTML
    else {
      if (typeof error !== 'string') {
        const errors = Object.values(error);
        errors.map((singularError: string) => toast.error(t(singularError)));
      } else {
        toast.error(t('toasts.request_error'));
      }
      setTokenInvalid(false);
      cleanError();
    }
    return error;
  };

  useEffect(() => {
    if (errorRequest) {
      const errorDataRequest = errorRequest as ErrorDataType;
      if (errorDataRequest?.data) {
        validateError(errorDataRequest.data);
      } else {
        validateError(errorRequest);
      }
    }
  }, [errorRequest]);

  const clearAppStorage = () => {
    cleanError();
    localStorage.clear();
    window.location.replace('/');
  };

  return (
    <>
      {tokenInvalid ? (
        <div className="tokenInvalid text-center">
          <div className="tokenInvalid_container">
            <Image src={Icon401} alt="img_401" width="25%" fluid />
            <div className="my-4">
              <h1 className="fw-bold">
                {t('components.errorBoundary_back_title')}
              </h1>
              <p>{t('components.errorBoundary_back_message')}</p>
            </div>
            <Button text="btns.login" onClick={clearAppStorage} fullWidth />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorBoundaryBack;
