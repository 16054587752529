import i18next from 'i18next';

import back_es from './es/back.json';
import front_es from './es/front.json';

const resources = {
  es: {
    allTranslations: { ...front_es, ...back_es }
  }
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources,
  ns: ['allTranslations']
});

export const tnl = i18next.t;
export default i18next;
