export default [
  {
    pk: 1,
    page_name: 'Cáncer de mama',
    indicators: [
      { name: 'CA. Mama', uuid: '019f6fda-2e68-4c9c-8087-d6cfca89c419' }
    ]
  },
  {
    pk: 2,
    page_name: 'Cáncer de prostata',
    indicators: []
  },
  {
    pk: 3,
    page_name: 'Cáncer de colon',
    indicators: []
  },
  {
    pk: 4,
    page_name: 'Cáncer de cervix',
    indicators: []
  },
  {
    pk: 5,
    page_name: 'Cáncer de leucemia',
    indicators: []
  },
  {
    pk: 6,
    page_name: 'Cáncer de estómago',
    indicators: []
  },
  {
    pk: 25,
    page_name: 'Oncología general',
    indicators: []
  },
  {
    pk: 27,
    page_name: 'Gestante ingreso',
    indicators: [
      { name: 'Ingreso', uuid: '78974dfc-2cdc-43e9-9711-45de9a0f8502' }
    ]
  },
  {
    pk: 28,
    page_name: 'Gestante 1',
    indicators: []
  },
  {
    pk: 29,
    page_name: 'Gestante 2',
    indicators: []
  },
  {
    pk: 30,
    page_name: 'Gestante 3',
    indicators: []
  },
  {
    pk: 31,
    page_name: 'Gestante - Evento obstétrico',
    indicators: []
  },
  {
    pk: 32,
    page_name: 'Gestante - Postnatal',
    indicators: []
  },
  {
    pk: 33,
    page_name: 'Gestante - Frecuencia',
    indicators: [
      { name: 'Gestante', uuid: 'c17e6109-c5a7-4a96-8191-e467982d76df' }
    ]
  },
  {
    pk: 34,
    page_name: 'Gestante - Hospitalización',
    indicators: []
  },
  {
    pk: 37,
    page_name: 'Hipertensión',
    indicators: [
      { name: 'Hipertensión 1', uuid: 'd1078b11-aaaa-4561-9671-1fba63659af0' },
      { name: 'Hipertensión 2', uuid: 'e22d073c-a245-4422-829e-534016f3cfbc' }
    ]
  },
  {
    pk: 39,
    page_name: 'Diabetes',
    indicators: [
      { name: 'Diabetes 1', uuid: 'd1078b11-aaaa-4561-9671-1fba63659af0' },
      { name: 'Diabetes 2', uuid: 'e22d073c-a245-4422-829e-534016f3cfbc' }
    ]
  }
] as WorkUnit[];
