export default {
  '--app-primary-color': 'hsl(233, 24%, 14%)',
  '--app-secondary-color': 'hsl(233, 19%, 37%)',
  '--app-tertiary-color': 'hsl(240, 16%, 90%)',
  '--app-disabled-color': 'hsl(0, 0%, 82%)',
  '--sidebar-bg-color': 'hsl(233, 24%, 14%)',
  '--sidebar-icon-color': 'hsl(0, 0%, 100%)',
  '--sidebar-text-color': 'hsl(0, 0%, 100%)',
  '--sidebar-active-bg-color': 'hsl(342, 100%, 45%)',
  '--sidebar-active-text-color': 'hsl(0, 0%, 100%)'
};
