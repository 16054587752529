import { create } from 'zustand';

interface ErrorDetail {
  detail: string;
}
interface DjangoErrors {
  non_field_errors?: string[];
  username?: string[];
  email?: string[];
}
export interface ErrorCode {
  code: string;
}
export type ErrorType = ErrorDetail | DjangoErrors | ErrorCode | string;
export interface ErrorDataType {
  data: ErrorType;
}

export interface AppStorage {
  isDoingRequest: boolean;
  errorRequest: ErrorType | null;
  doingRequest: () => void;
  requestFinalized: () => void;
  saveUserError: (error: ErrorType) => void;
  saveServerError: () => void;
  cleanError: () => void;
}

export const useAppStorage = create<AppStorage>()((set) => ({
  isDoingRequest: false,
  errorRequest: null,
  doingRequest: () => set({ isDoingRequest: true }),
  requestFinalized: () => set({ isDoingRequest: false }),
  saveUserError: (error: ErrorType) => {
    let errorToDisplay = { detail: 'toasts.request_error' } as ErrorType;
    if (error) {
      errorToDisplay = error;
    }
    set({ errorRequest: errorToDisplay });
  },
  saveServerError: () => set({ errorRequest: { detail: 'server_error' } }),
  cleanError: () => set({ errorRequest: null })
}));
