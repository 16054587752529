import type { SidebarData } from '~layouts/Sidebar/SidebarTypes';

export const ROUTES = {
  ROOT: '/',
  ERROR: '/404',
  LOGIN: '/login',
  PLATFORM: '/platform',

  WORK_LIST: '/platform/work_list',
  CREATE_LIST: '/platform/create_list',
  FILE_LOAD: '/platform/file_load',
  FILE_HISTORY: '/platform/file_history',
  SPECIALTY: '/platform/specialty',
  PATIENT_LIST: '/platform/patient_list',
  COHORT: '/platform/cohort',
  OBSERVATION_ZONE: '/platform/observation_zone',
  LOCATION_WITH_PATIENTS: '/platform/location_with_patients',
  WORK_UNITS: '/platform/work_units',
  USER_UPDATE: '/platform/user_update',
  USER_CREATE: '/platform/user_create',
  USERS: '/platform/users',
  GROUPS_MANAGEMENT: '/platform/groups_management'
};

export { default as PrivateRoute } from './PrivateRoute';
export { default as PublicRoute } from './PublicRoute';

const privateRouteItemHandler = (item: SidebarData) => {
  if (item.children.length === 0) return item;
  else return item.children.map((child) => child).flat();
};

export const privateRouteOptions = (data: SidebarData[] = []) => {
  return data
    .map((item) => {
      if (item.children.length === 0) return item;
      else
        return item.children
          .map((child) => privateRouteItemHandler(child))
          .flat();
    })
    .flat();
};
