import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomIcon, type IconName } from '~components/index';
import InputLabel from '../InputLabel/InputLabel';
import type { CommonInputProps } from '../InputsTypes';
import '../inputs.scss';

interface Props extends CommonInputProps {
  type?: 'text' | 'number' | 'date' | 'datetime-local' | 'password';
  min?: string;
  step?: string;
  iconName?: IconName;
  defaultValue?: string | number;
  max?: string;
  hookOnChange?: (value: string) => void;
  onInputChange?: (value: string) => void;
}

const Input = ({
  type = 'text',
  name,
  label,
  placeholder,
  step,
  labelHelper,
  iconName,
  defaultValue,
  disabled = false,
  required = false,
  design = 'col-12',
  hookError,
  min,
  max,
  onInputChange = () => {},
  hookOnChange = () => {}
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`customInput ${design} ${
        disabled ? 'customInput_disabled' : ''
      }`}
    >
      {label && (
        <InputLabel
          helper={labelHelper}
          name={name}
          text={label}
          required={required}
        />
      )}
      <div className="customInput_container">
        {iconName && (
          <CustomIcon
            className="customInput_icon"
            name={iconName}
            color="var(--gray)"
          />
        )}
        <input
          type={type}
          id={name}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder && t(placeholder)}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={(e) => {
            const value = e.target.value;
            hookOnChange(value);
            onInputChange(value);
          }}
          onWheel={(e) => {
            (e.target as HTMLElement).blur();
            e.stopPropagation();
          }}
        />
      </div>
      {hookError?.message && (
        <p className="customInput_error">{t(hookError?.message)}</p>
      )}
    </div>
  );
};

const MemoizeInput = memo(Input);
export default MemoizeInput;
